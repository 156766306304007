.header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  & .header-container {
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 5.5px;
    color: white;
    min-width: 480px;
    width: min(1640px, 100vw);
    min-width: 1031px;
    height: 100%;
    & .select-branch-component {
      margin: auto;
      margin-right: 20px;
    }
    & .header-button {
      padding: 0;
      height: 60px;
      margin: 0 10px 0 10px;
      text-decoration-line: none;
      color: #585858;
      line-height: 44px;
      &:has(.menu-item-user) {
        line-height: unset;
      }
    }

    // 헤더 왼쪽 영역 (조합 로고, 통합검색)
    & .header-left-container {
      display: flex;
      // 조합 로고
      & .header-logo {
        cursor: pointer;
        margin-left: 20px;
        margin-right: 20px;
      }
      // 통합 검색 영역
      & .total-search-container {
        width: 450px;
        display: flex;
        padding: 11px 10px 11px 50px;
        margin: 0;
        & .input-field {
          height: 38px;
          width: 300px;
          & input {
            height: 38px;
            padding-top: 0;
            padding-bottom: 0;
            font-weight: 500;
          }
        }
        & .search-button {
          height: 38px;
          margin-left: 10px;
          background: #41506e;
          box-shadow: none;
        }
      }
    }

    // 헤더 오른쪽 영역 (서점정보, 로그인/아웃 버튼)
    & .header-right-container {
      display: flex;
      & .store-info-container {
        display: flex;
        & :nth-child(2) {
          margin-left: auto;
        }
        & .cart-icon {
          width: 15px;
          height: 15px;
          margin-right: 3px;
        }
        & .cart-text {
          margin-left: 0;
          line-height: 44px;
        }
        & .user-name-text {
          min-width: 60px;
          line-height: 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-transform: none !important;
        }
        & .credit-balance-text {
          height: 60px;
          line-height: 60px;
          color: #585858;
          margin: 0 10px;
          min-width: 185px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & .cart-button {
          min-width: 60px;
        }
        & .menu-line {
          color: #585858;
          height: 60px;
          line-height: 60px;
          // margin-left: 20px;
        }
        & button,
        div {
          font-size: 12px;
          font-weight: 400;
        }
      }
      // 메뉴
      .header-menu-warp {
        position: relative;
        display: flex;
        align-items: center;
        > .menu-btn {
          width: 20px;
          margin-left: 4px;
        }
        > .menu-item-list {
          // width: 100px;
          height: 120px;
          padding: 16px;
          background-color: #fff;
          position: absolute;
          z-index: 99999;
          top: 40px;
          right: 0;
          border-radius: 8px;
          border: 1px solid #e5eaeb;
          box-shadow: 0px 3px 6px #e5e5e5;
          transition: all 0.3s ease-in-out;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          > .menu-item-user {
            color: #000;
          }
          > .menu-item-btn-list {
            margin-top: 32px;
            display: flex;
            gap: 8px;
            position: relative;
            &::after {
              content: "";
              width: 100%;
              height: 1px;
              background-color: #e5e5e5;
              position: absolute;
              top: -16px;
            }
          }
          &.close {
            width: auto;
            max-height: 0;
            border: 0;
            padding: 0 16px;
            opacity: 0;
          }
          &.open {
            max-height: 120px;
            opacity: 1;
          }
          // 로그아웃 버튼
          & .header-button {
            height: 32px;
            line-height: 1;
            margin: 0;
            color: #236daa;
            border: 1px solid #236daa;
            border-radius: 8px;
            width: 100px;
            &:hover {
              font-weight: 700;
              background-color: #236daa;
              color: #fff;
              border: 1px solid #236daa;
            }
          }
        }
      }
    }
  }

  & .menu-wrapper {
    width: 100%;
    height: 50px;
    background-color: #124e82;
    display: flex;
    flex-direction: column;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 70px;
    overflow: hidden;
    &:hover {
      width: 100%;
      height: 330px;
    }
    & .menu-container {
      display: flex;
      flex-direction: row;
      & .parent-menu-item {
        min-width: 200px;
        height: 50px;
        line-height: 50px;
        &:hover {
          & .indicator {
            background-color: white;
            width: 200px;
          }
        }
        & a {
          text-decoration: none;
          color: white;
        }
        & .indicator {
          position: absolute;
          width: 0px;
          height: 5px;
          background-color: #124e82;
          top: 45px;
          z-index: 3;
          -webkit-transition: width 0.5s;
          -o-transition: width 0.5s;
          transition: width 0.5s;
        }
        & .active {
          background-color: white;
          width: 200px;
        }
      }

      & .parent-menu-item:nth-of-type(1) {
        margin-left: auto;
      }
      & .parent-menu-item:nth-last-of-type(1) {
        margin-right: auto;
      }
    }
  }

  & .child-menu-wrapper {
    width: 100%;
    height: 280px;
    background-color: #eeeeee;
    border-bottom: 1px solid #777777;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 100;
    & .child-menu-container {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      min-width: 200px;
      position: relative;
      z-index: 101;
      & .child-menu {
        height: 30px;
        & .child-menu-link {
          text-decoration: none;
          color: black;
        }
        & .child-menu-link:hover {
          color: #236daa;
        }
      }
      // 주문현황 - 반품신청 구분선 처리
      &:nth-last-of-type(3) {
        & .child-menu:nth-of-type(6) {
          padding-top: 10px;
          border-top: 2px solid #cccccc;
        }
      }
    }
    & .child-menu-container:nth-of-type(1) {
      margin-left: auto;
    }
    & .child-menu-container:nth-last-of-type(1) {
      margin-right: auto;
    }
  }
}
